h1 {
  font-size: 6rem !important;
}

h3 {
  color: #000000b5;
}

.hero {
  margin-top: 4vh;
}

@media (max-width: 600px) {
  .hero {
    margin-top: 1vh; /* Adjust this value as needed */
  }
}

.leader {
  margin-top: 4vh;
}
